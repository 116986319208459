html,
body {
  padding: 0;
  margin: 0;
  font-family: soleil, Helvetica Neue, sans-serif;
  background-color: #e2e8f0;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}


@keyframes fadein {
  0% {opacity:0;}
  100% {opacity:1;}
  }